<template>
  <div class="flex flex-col items-center">
[log img view]
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import Header from '@/components/Header.vue'

export default {
  data() {
    return {
      customText: '',
      imageUrl: '' // URL for the generated image
    };
  },
  components: {
    Button,
    Header
  },
  mounted(){
  },
  methods: {
  }
};
</script>