<template>
  <div class="font-game text-gray-700 p-2 text-lg font-bold my-2">{{label}}</div>
</template>

<script>
import { ref } from 'vue'


export default {
  name: 'SetStats',
  components:{
  },
  props: {
    label: String
  }
}
</script>

