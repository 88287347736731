<template>
  <div class="w-full flex flex-row">
    <div class="w-64 bg-green-600 text-white rounded-br text-left p-4 flex-shrink-0 font-desc" style="height: 95vh">
      <div class="text-xl font-game mb-3">AMI Tools</div>
      <div v-for="option in [
        { label: 'AMI Header Generator', route: '/header' },
        { label: 'CULogo Generator', route: '/culogo' }  
      ]" :key="option.label"
        @click="this.$router.push(option.route)"
        :class="`${option.route == this.$route.path ? 'opacity-100' : 'opacity-70 hover:opacity-100'} cursor-pointer  duration-150 py-2`">
        {{option.route == this.$route.path ? '[X]' : '[-]'}}{{option.label}}
      </div>
    </div>
    <div class="max-w-screen-lg w-full mx-auto">
    <router-view/>
    </div>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
