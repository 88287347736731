<template>
  <div class="font-game bg-green-500 cursor-pointer hover:bg-green-600 duration-150 p-2 text-white rounded-sm">{{label}}</div>
</template>

<script>
import { ref } from 'vue'


export default {
  name: 'SetStats',
  components:{
  },
  props: {
    label: String
  }
}
</script>

