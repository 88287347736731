import { createRouter, createWebHistory } from 'vue-router'
import HeaderGenView from '../views/HeaderGenView.vue'
import HomeView from '../views/HomeView.vue'
import CuImgView from '../views/CuImgView.vue'
import LogImgView from '../views/LogImgView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/culogo',
    name: 'CuImgView',
    component: CuImgView
  },
  {
    path: '/logimg',
    name: 'LogImgView',
    component: LogImgView
  },
  {
    path: '/header',
    name: 'HeaderGenView',
    component: HeaderGenView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
