<template>
  <div class="flex flex-col items-center">
    <Header label="Generate AMI Header Image" />
    <div class="w-96 mx-auto flex flex-col">
      <input v-model="customText" @keyup.enter="generateImage" ref="input" type="text" placeholder="Enter Header Text" class="p-2 border rounded-sm">
      <Button @click="generateImage" label="Generate Header" class="mt-2" />
    </div>
    <div v-if="imageUrl" class="mt-10 flex flex-col bg-white rounded-sm px-5 pb-5 pt-3">
      <div class="text-xs uppercase font-desc text-left">Generated Image:</div>
      <img :src="imageUrl" alt="Generated Image">
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import Header from '@/components/Header.vue'

export default {
  data() {
    return {
      customText: '',
      imageUrl: '' // URL for the generated image
    };
  },
  components: {
    Button,
    Header
  },
  mounted(){

    this.$refs.input.focus()
  },
  methods: {
    async generateImage() {
      // Construct the URL with the custom text for the PHP script
      const params = new URLSearchParams({
        customText: this.customText
      }).toString();

      // Point this to your PHP backend URL
      const requestUrl = `https://w.vector8.xyz/amiheader.php?${params}`;

      // Use fetch to make a request to the backend PHP script
      try {
        const response = await fetch(requestUrl);

        if (response.ok) {
          // Set the image URL to display it in the <img> tag
          this.imageUrl = requestUrl;
        } else {
          console.error('Error generating image:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    }
  }
};
</script>